@media (max-height: 600px) and (max-width: 767px) {
  .content-wrapper {
    height: auto !important;
  }
  .demo-text {
    visibility: hidden;
  }
}
.login-wrapper {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 30;
  @media (max-width: 767px) {
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
  }
  .content-wrapper {
    top: 0px;
    @media screen and (max-width: 563px) {
      row-gap: 35px;
      padding-top: 100px;
    }
  }

  .right-0 {
    right: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .login-background {
    width: 80%;
    max-width: 1422px;
    min-height: 650px;
    background: #1b1c26;
    box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    margin-top: 5px;
    margin-bottom: 50px;
    z-index: 1000;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @media (max-height: 700px) {
      justify-content: flex-start;
    }
    .gradient-overlay {
      @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background: linear-gradient(180deg, rgba(27, 28, 38, 0) 0%, #1b1c26 100%);
      }
    }
    .stone-image {
      width: 100%;
      height: 66%;
      @media (max-width: 767px) {
        // object-fit: cover;
      }
    }
    .first-login-header {
      position: absolute;
      width: 758px;
      // height: 182px;
      left: calc(50% - 758px / 2 + 1px);
      top: 475px;
      font-family: 'Louis George Café', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 93px;
      line-height: 91px;
      text-align: center;
      letter-spacing: -8px;
      color: #ffffff;
      @media (max-width: 991px) {
        font-size: 75px;
        width: 550px;
      }
      @media (max-width: 767px) {
        color: #fff;
        text-align: center;
        font-size: 53px;
        font-style: normal;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: -2px;
        display: flex;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        flex-direction: column;
        flex-shrink: 0;
      }
      @media (max-width: 479px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .first-login-description {
      position: absolute;
      width: 638px;
      height: 60px;
      left: calc(50% - 638px / 2);
      top: 687px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      opacity: 0.7;
      @media screen and (max-width: 767px) {
        display: flex;
        width: 100%;
        padding-left: 60px;
        padding-right: 60px;
        flex-direction: column;
        flex-shrink: 0;
        color: #fff;
        text-align: center;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
      }
    }
    .yellow-btn {
      position: absolute;
      width: 277px;
      height: 78px;
      left: calc(50% - 277px / 2);
      top: 802px;
    }
    .yellow-btn-demo {
      position: absolute !important;
      width: 50px;
      height: 50px;
      left: 50px;
      top: 562px;
      border-radius: 50%;
      background-color: #fcdfac;

      @media (max-width: 767px) {
        position: static !important;
      }
      @media (max-height: 600px) {
        top: 300px;
      }
      @media (max-height: 600px) and (max-width: 767px) {
        position: absolute !important;
        top: 260px;
      }
    }
  }
  .close-btn {
    cursor: pointer;
    width: 52px;
    height: 52px;
    right: 25px;
    top: 30px;
    z-index: 20;
    @media screen and (max-width: 767px) {
      right: 15px;
      top: 25px;
      img {
        width: 35px;
      }
    }
  }
  .bottom {
    height: 33%;
    width: 100%;
    bottom: 0px;
    background: #1b1c26;
    box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
  }
  .progress-wrapper {
    background-color: transparent;
    top: 30px;
    height: 50px;
    width: 100%;
  }
  .progress {
    background-color: transparent;
    height: 100px;
    // width: 500px;
    max-width: 700px;
  }
  .circle-wrapper {
    // width: 126px;
    width: auto;
    .text {
      // width: calc(auto + 100px);
      padding-left: 20px;
      padding-right: 20px;
      color: #44454f;
      &::before {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #44454f;
        top: -40px;
        left: calc(100% / 2 - 10px);
      }
      &::after {
        content: '';
        position: absolute;
        background: #44454f;
        width: 100px;
        height: 2px;
        top: -27.5px;
        left: calc(100% / 2 + 16px);
      }
      &.green-label {
        &::before {
          content: '';
          position: absolute;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: #57bc18;
          top: -40px;
          left: calc(100% / 2 - 10px);
        }
        &::after {
          content: '';
          position: absolute;
          background: #57bc18;
          width: 100px;
          height: 2px;
          top: -27.5px;
          left: calc(100% / 2 + 16px);
        }
      }
      &.white-label {
        color: white;
        &::before {
          content: '';
          position: absolute;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: white;
          top: -40px;
          left: calc(100% / 2 - 10px);
        }
      }
    }
    .text.end {
      padding-left: 10px;
      padding-right: 0px;
      &::after {
        content: '';
        display: none;
      }
    }
    .invisible-width {
      height: 30px;
      color: white;
      max-width: calc(100% + 77px);
    }
  }
  .login-header {
    font-family: 'Louis George Café';
    font-style: normal;
    font-weight: 700;
    font-size: 73px;
    text-align: center;
    color: #ffffff;
    letter-spacing: -3px;
    margin-bottom: 0;
    margin-top: 20px;
    @media (max-width: 991px) {
      font-size: 63px;
    }
    @media screen and (max-width: 767px) {
      color: #fff;
      text-align: center;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -2px;
      display: flex;
      width: 344px;
      flex-direction: column;
      flex-shrink: 0;
    }
  }
  .login-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    max-width: 640px;
    opacity: 0.7;
    @media screen and (max-width: 767px) {
      display: flex;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
    }
  }
  .section-2 {
    overflow-y: auto;
    margin-top: 136px;
    @media (max-width: 991px) {
      margin-top: 95px;
    }
    @media (max-height: 650px) {
      margin-top: 50px;
    }
  }
  .section-3 {
    .upload-image-wrapper {
      // opacity: 0.4;
      color: #7b2d2d;
      width: 300px;
      height: 300px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='60' ry='60' stroke='%235F6067FF' stroke-width='2' stroke-dasharray='8%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 60px;
      cursor: pointer;
      img {
        opacity: 1;
      }
      .description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        max-width: 244px;
      }
    }
  }
  .upload-image-nickname {
    @media screen and (max-width: 563px) {
      width: 100vw;
    }
    .image-wrapper {
      width: 127px;
      flex-shrink: 0;
      cursor: pointer;
      overflow: hidden;
      img {
        height: 127px;
      }
      span {
        color: #ffffff;
      }
    }
    .no-image {
      border-radius: 20px;
      border: 1px dashed #fff;
      opacity: 0.4000000059604645;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
      }
    }
    .slam-image {
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 30px;
    }
    .google-wrapper {
      &::before {
        content: '';
        position: absolute;
        width: 57px;
        height: 5px;
        /* border-radius: 50%; */
        background: white;
        bottom: -1px;
        left: -1px;
        opacity: 0.4;
      }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 80%;
        border-radius: 50%;
        background: white;
        right: 0;
      }
    }
    .nickname-input {
      height: 80px;
      color: #fcdfac;
      background-color: transparent;
      border: none;
      max-width: 450px;
      font-family: 'Louis George Café';
      font-style: normal;
      font-size: 73px;
      line-height: 91px;
      letter-spacing: -3px;
      border-bottom: 4px solid #4b4c54;
      @media screen and (max-width: 767px) {
        font-size: 36px;
        line-height: 1;
        letter-spacing: normal;
        width: 80%;
        border: 4px solid rgba(255, 255, 255, 0.1);
        border-radius: 50px;
        padding: 20px;
      }
      &:focus {
        outline: none;
        border: none;
        border-bottom: 4px solid #4b4c54;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #fcdfac;
      transition: background-color 500s ease-in-out 0s, color 500s ease-in-out 0s, line-height 500s ease-in-out 0s;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .profile-image {
    height: 121px !important;
    width: 121px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
  }
  .btn-next-wr {
    @media screen and (max-width: 563px) {
      width: 100vw;
    }
  }
  .btn-next {
    margin-bottom: 55px;
    font-family: 'Louis George Café';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #0b0c17;
    padding: 30px 40px;
    background: #fcdfac;
    border-radius: 12321px;
    text-transform: uppercase;
    text-decoration: none;
    .arrow-font {
      font-family: 'Inter';
    }
    &.disabled {
      cursor: default;
      background: #44454f;
      border: none;
    }
  }

  .kyc-btn-section {
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    .yellow-btn {
      text-transform: uppercase;
      text-decoration: none;
      font-family: 'Louis George Café';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #0b0c17;
    }
  }
  .regular-btn {
    background: transparent;
    border: none;
    height: 78px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #fcdfac;
    text-decoration: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid green;
    -webkit-text-fill-color: green;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .nickname-taken {
    color: #f66666 !important;
  }
  .red-x {
    margin-left: 20px;
    margin-top: 13px;
    height: 50px;
    cursor: pointer;
  }
  .nickname-description-taken {
    color: #f66666;
    font-size: 24px;
    font-family: Louis George Cafe;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.2px;
  }

  @media (max-width: 767px) {
    .mobile-background {
      height: 100vh !important;
    }
    .mb-150 {
      margin-bottom: 150px;
    }
  }
  @media (max-height: 600px) and (min-width: 800px) {
    .mobile-background {
      height: 100vh !important;
    }
    .h-100 {
      height: auto !important ;
    }
    .first-login-header {
      font-size: 75px !important;
      height: 160px;
    }
    .yellow-btn {
      margin: 5px;
      height: 50px !important;
    }
    .demo-text {
      visibility: hidden;
    }
    .demo-h1 {
      height: 80px;
    }
    .demo-from {
      padding-top: 5% !important;
    }
  }

  .overflow-y-scroll {
    position: fixed !important;
    // overflow-y: scroll;
  }
  .mt-180 {
    margin-top: 180px;
  }
  .justify-around-mobile {
    @media (max-width: 767px) {
      // justify-content: space-around !important;
    }
  }
  .flex-1 {
    flex: 1;
  }

  .demo-input {
    background: transparent;
    border: 1px solid #fcdfac;
    width: 10%;
    color: #fcdfac;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
  }

  .demo-h1 {
    font-size: 73px !important;
    margin-bottom: 5%;
    margin-top: 10%;
    min-width: 360px;
    @media (max-width: 767px) {
      font-size: 53px !important;
    }
  }
  .demo-text {
    position: absolute !important;
    font-size: 18px;
    color: #fcdfac !important;
    left: 111px;
    top: 574px;
    @media (max-width: 767px) {
      position: static !important;
      padding-top: 12px;
      padding-left: 4px;
    }
  }
  .demo-btn-text {
    @media (max-width: 767px) {
      display: flex;
      padding-top: 10%;
    }
    @media (max-width: 564px) {
      padding-top: 0;
    }
  }

  .demo-from {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 20% 0 20%;
    margin-bottom: 5%;
    .demo-input {
      width: 80%;
      text-align: center;
      color: #fcdfac;
    }
    .demo-btn {
      margin-top: 2%;
      width: 217px !important;
      height: 58px !important;
      position: relative; // Relative positioning
      overflow: hidden; // Hide overflow if icon is larger
    }
    .promo-btn {
      font-family: 'Louis George Café';
      margin-top: 2%;
      width: 217px !important;
      height: 58px !important;
      position: relative; // Relative positioning
      overflow: hidden; // Hide overflow if icon is larger
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      border: none;
    }
    .profile-demo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
    }
    .demo-res {
      color: #fcdfac !important;
      font-family: 'Louis George Café', sans-serif;
      font-size: 22px;
      text-align: center;
      min-height: 30px;
      margin-top: 10%;
    }
    .demo-res-hidden {
      visibility: hidden;
      min-height: 30px;
      margin-top: 10%;
    }
  }

  .yellow-btn-demo:hover {
    background: #f9d48b;
  }
  .yellow-btn-demo:active {
    background: #f9d48b;
    --bs-btn-active-bg: #f9d48b !important;
  }
  .yellow-btn-demo:focus-visible {
    background: #f9d48b;
    --bs-btn-active-bg: #f9d48b !important;
  }
}

.get-promo-h1 {
  width: 800px !important;
  font-size: 80px !important;
  line-height: 65px !important;
  letter-spacing: -5px !important;
}

.get-promo-p {
  margin-top: 15px;
  height: 40px !important;
}

.promo-h1 {
  font-size: 79px !important;
}

.play-btn-wrapper {
  width: 240px;
  height: 90px;
  position: relative;
}

.play-btn-wrapper .play-text {
  position: absolute;
  top: 38%;
  left: 23%;
  z-index: 2;
  font-family: 'Louis George Café';
  color: #24624b;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  width: 130.327px;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    top: 25% !important;
  }
}

.promo-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  & .collection_input {
    width: 100%;
    height: 50px;
    border: 1px solid #ebebeb;
    color: #fff;
    background-color: transparent;
    border-radius: 50vw;
    flex: none;
    margin-bottom: 0;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;

    &.error {
      border: 1px solid red;
    }
  }
}

.mt-promo {
  padding-top: 15% !important;
}

.promo-res {
  margin-top: 6% !important;
}
