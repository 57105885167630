.error-page-wrapper {
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  .contents {
    h1 {
      color: #fcdfac;
      text-align: center;
      text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.85);
      font-family: 'Louis George Café', sans-serif;
      font-size: 28px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1px;
    }
    h2 {
      color: #fff;
      text-align: center;
      text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.85);
      font-family: 'Louis George Café', sans-serif;
      font-size: 133px;
      font-style: normal;
      font-weight: 700;
      line-height: 121px;
      letter-spacing: -8px;
    }
    p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.85);
      font-family: 'Inter', sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 20px;
      text-transform: uppercase;
    }
    button {
      color: #0b0c17;
      font-family: 'Louis George Café', sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
