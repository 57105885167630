.pixelstream {
  .pixel-btn {
    color: black;
    position: absolute;
    top: 50%;
    z-index: 6;
  }
  .slot-machine-iframe {
    display: none;
    z-index: 5;
  }
  .close-button {
    position: absolute;
    z-index: 10;
    top: 30px;
    right: 50px;
    img {
      cursor: pointer;
      width: 30px;
    }
  }
}
