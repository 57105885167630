.client-hub-login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  position: relative;

  @media (max-width: 767px) {
    min-height: 100vh;
    height: unset;
  }

  & .video-background {
    width: 100%;
    height: 100%;

    @media (max-width: 767px) {
      height: 80vh;
    }
    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .login-form {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 40vh;
    min-width: 700px;
    max-width: 1440px;
    max-height: 500px;

    @media (max-width: 1800px) {
      height: 35vh;
    }
    @media (max-width: 1200px) {
      height: fit-content;
    }
    @media (max-width: 767px) {
      min-width: 300px;
      width: 84vw;
      height: 40vh;
      max-height: 600px;
      top: 35%;
    }

    & form {
      display: grid;
      grid-template-columns: 3fr 2fr;
      background-color: rgba($color: #fefeff, $alpha: 0.21);
      border: 1px solid #fff;
      border-radius: 30px;
      padding: 30px 60px;
      height: 100%;
      width: 100%;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        padding: 35px;
      }

      & .col {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 30px;
        height: 100%;

        @media (max-width: 767px) {
          justify-content: flex-start;
          width: 100%;
        }

        & .row {
          display: flex;
          justify-content: flex-start;
          gap: 100px;
          margin: 0;
          flex-wrap: nowrap;
          width: 100%;

          @media (max-width: 767px) {
            flex-direction: column;
            gap: 50px;
          }
        }

        & .row > * {
          width: unset;
          padding: unset;
          margin: unset;
        }

        &.left {
          padding-left: 20px;
          @media (max-width: 767px) {
            padding-left: 0;
          }
        }
        &.right {
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }

        & .title h1 {
          margin: 0;
          padding: 0;
          letter-spacing: 2px;
          font-family: 'Resolve Bold';
          color: #fff;
          text-transform: uppercase;
          font-size: 30px;
          padding-bottom: 10px;
        }

        & .client-hub-input {
          background-color: transparent !important;
          border: none;
          appearance: none;
          width: 100%;
          padding: 3px 4px;
          border-bottom: 1px solid #fefeff;
          color: #d9d9d9;
          font-family: 'Space Grotesk Light';

          &:-webkit-autofill {
            background-color: transparent !important;
          }
          &:focus {
            outline: none;
          }
        }
        & .checkbox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          & label {
            line-height: 1;
            font-family: 'Space Grotesk Light';
            color: #fff;
          }

          & input[type='checkbox'] {
            background: transparent;
            appearance: none;
            width: 18px;
            height: 18px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            border: 0.5px solid #fff;
            border-radius: 3px;

            &.checked {
              background-image: url('../../../images/client-hub/check.png');
              background-size: 70%;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        & img {
          width: 150%;
          height: 140%;
          object-fit: cover;
          position: absolute;
          left: 0%;
          bottom: -30px;
        }
        & .button {
          @media (max-width: 767px) {
            display: flex;
            justify-content: center;
          }
        }
        & button[type='submit'] {
          background-color: #e2001b;
          color: #d9d9d9;
          border: none;
          padding: 10px 50px;
          border-radius: 20px;
          font-family: 'Space Grotesk Bold';
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 4px;

          & img {
            position: relative;
            width: 10px;
            height: 10px;
            bottom: unset;
            left: unset;
          }
        }
      }
    }
  }

  & .login-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    @media (max-width: 767px) {
      position: relative;
    }
  }
}
