@import './../../vars.scss';

.footer {
  z-index: 1;
  position: relative;
  .footer-video--wr {
    position: relative;
    .footer-video {
      display: flex;
      @media screen and (max-width: 479px) {
        width: 100vw;
        height: 50vh;
      }
    }
    .footer-body {
      z-index: 1;
      width: 100%;
      height: 100%;
      max-width: 1000px;
      grid-row-gap: 40px;
      flex-direction: column;
      justify-content: flex-start;
      place-items: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 40px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: 0%;
      right: 0%;
      @media screen and (max-width: 1260px) {
        grid-row-gap: 20px;
        padding-top: 0;
        padding-bottom: 0;
      }
      @media screen and (max-width: 810px) {
        grid-row-gap: 40%;
      }
      @media screen and (max-width: 510px) {
        grid-row-gap: 0px;
      }

      .footer-h2 {
        text-align: center;
        letter-spacing: -4px;
        margin-top: 0;
        margin-bottom: 0;
        font-family: 'Louis George Café', sans-serif;
        font-size: 93px;
        font-weight: 700;
        line-height: 0.8;
        color: white;
        @media screen and (max-width: 1515px) {
          font-size: 69px;
        }
        @media screen and (max-width: 991px) {
          padding-left: 20px;
          padding-right: 20px;
          font-size: 59px;
        }
        @media screen and (max-width: 884px) {
          font-size: 48px;
          padding-left: 15%;
          padding-right: 15%;
        }
        @media screen and (max-width: 767px) {
          letter-spacing: -2px;
          font-size: 43px;
          padding-left: 20px;
          padding-right: 20px;
        }
        @media screen and (max-width: 479px) {
          letter-spacing: -1px;
          font-size: 28px;
          line-height: 1;
        }
      }
      .access-btn {
        height: 86px;
        color: #fff;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
        text-transform: uppercase;
        background-color: #b70aff;
        border-radius: 50vw;
        align-items: center;
        padding: 0 60px;
        font-family: 'Louis George Café', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        display: flex;
        cursor: pointer;
        border: 0;
        text-decoration: none;
        @media screen and (max-width: 810px) {
          height: 44px;
          -webkit-text-stroke-width: 0px;
          background-color: transparent;
          border: 2px solid #fff;
          margin-top: 30px;
          padding-left: 23px;
          padding-right: 23px;
          font-size: 14px;
          &:hover {
            background-color: #b70aff;
            border: 2px solid #b70aff;
          }
        }
        @media screen and (max-width: 884px) {
          height: 46px;
        }
        @media screen and (max-width: 1260px) {
          height: 58px;
          padding-left: 45px;
          padding-right: 45px;
          font-size: 16px;
        }
        @media screen and (max-width: 1515px) {
          height: 69px;
          padding-left: 50px;
          padding-right: 50px;
          font-size: 20px;
        }
      }
    }
    .h-background--gradient {
      width: 100%;
      height: 17vh;
      background-image: linear-gradient(transparent, #1b1c26 95%);
    }
    .is--absolute {
      height: 40vh;
      background-image: linear-gradient(#1b1c26 14%, transparent);
      position: absolute;
      top: 0;
      @media screen and (max-width: 479px) {
        height: 30vh;
      }
    }
    .is--footer {
      @media screen and (max-width: 479px) {
        height: 20vh;
        background-image: linear-gradient(#1b1c26 30%, transparent);
      }

      @media screen and (max-width: 767px) {
        height: 20vh;
      }
      @media screen and (max-width: 991px) {
        height: 23vh;
      }
    }
  }
  .footer-yellow {
    background-color: #f90;
    margin-top: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    @media screen and (max-width: 479px) {
      margin-top: 0;
      padding: 24px 10px;
      overflow: hidden;
    }
    @media screen and (max-width: 991px) {
      padding: 20px;
    }

    .footer-yellow--txt {
      text-align: center;
      @media screen and (max-width: 479px) {
        letter-spacing: -1px;
        font-size: 26px;
      }
      @media screen and (max-width: 767px) {
        letter-spacing: 0;
        font-size: 31px;
      }
      @media screen and (max-width: 991px) {
        letter-spacing: -1px;
        font-size: 37px;
        line-height: 1;
      }
    }
    .span {
      color: #fff;
    }
    .footer-wr {
      max-width: 1440px;
      grid-column-gap: 8px;
      grid-row-gap: 14px;
      color: #0b0c17;
      letter-spacing: -2px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      font-family: 'Louis George Café', sans-serif;
      font-size: 45px;
      font-style: italic;
      font-weight: 1000;
      line-height: 1;
      display: flex;
      @media screen and (max-width: 991px) {
        grid-column-gap: 0px;
        flex-direction: column;
      }
      .contact-btn {
        letter-spacing: -1px;
        background-color: #fff;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 700;
        height: 68px;
        color: #0b0c17;
        text-transform: uppercase;
        border-radius: 50vw;
        align-items: center;
        padding: 0 40px;
        font-family: 'Louis George Café', sans-serif;
        font-style: normal;
        display: flex;
        text-decoration: none;
      }
    }
  }
  .footer-end {
    z-index: 1;
    grid-row-gap: 40px;
    background-color: #1b1c26;
    flex-direction: column;
    padding-top: 59px;
    padding-bottom: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 479px) {
      grid-row-gap: 0px;
    }
    @media screen and (max-width: 991px) {
      grid-row-gap: 30px;
      padding-top: 40px;
    }
    .footer-end-wr {
      width: 100%;
      max-width: 1440px;
      grid-column-gap: 5%;
      place-items: flex-end;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      font-weight: 100;
      @media screen and (max-width: 1520px) {
        padding: 0 3%;
      }
      @media screen and (max-width: 991px) {
        grid-column-gap: 0%;
        grid-row-gap: 20px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
      }
      .opacity-body {
        opacity: 0.7;
        line-height: 1.4;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 200;
      }

      .smaller {
        font-size: 14px;
      }

      .footer-end-text {
        color: white;
        opacity: 0.8;
        font-weight: 300;
      }
      .brand.w--current {
        flex: none;
      }
      .brand {
        color: #fff;
        font-size: 68px;
        display: block;
      }
      .w-inline-block {
        max-width: 100%;
        display: inline-block;
      }
      .spacing {
        width: 100%;
        grid-column-gap: 20px;
        justify-content: space-between;
        @media screen and (max-width: 479px) {
          align-items: center;
          display: none;
        }
        @media screen and (max-width: 991px) {
          grid-row-gap: 20px;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .social-block {
        flex: none;
        display: flex;
        .social-wr {
          width: 50px;
          height: 50px;
          color: #fff;
          border: 1px solid transparent;
          border-radius: 50vw;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          display: flex;
        }
        .social-wr:hover {
          color: #fcdfac;
          border-color: #fcdfac;
          transition: all 0.6s ease-in-out;
        }
      }
    }
  }
}

.white {
  color: rgba(255, 255, 255, 0.4) !important;
}
