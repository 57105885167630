@import './vars.scss';
// Importing "Zen Dot" font-family style
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
// Importing "Chakra Petch" font-family style
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap');
// Importing "React Toastify" font-family style
@import 'react-toastify/dist/ReactToastify.css';

body {
  box-sizing: border-box;

  background-color: #1b1c26;

  // background-color: #070a29;
  // background-image: url('./images/background.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

h1 {
  color: $primaryColor;
}

p {
  color: $primaryColor;
  margin-bottom: 0;
}
.yellow-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  background: #fcdfac;
  border-radius: 12321px;
  text-transform: uppercase;
}

.yellow-btn:hover {
  background: #f9d48b;
}

.yellow-btn:active {
  background: #f9d48b;
  --bs-btn-active-bg: #f9d48b !important;
}

.yellow-btn:focus-visible {
  background: #f9d48b;
  --bs-btn-active-bg: #f9d48b !important;
}

@media screen and (max-width: 991px) {
  body .h1 {
    letter-spacing: -5px;
    font-size: 80px;
    line-height: 0.8;
  }
}

@media screen and (max-width: 767px) {
  body .h1 {
    letter-spacing: -3px;
    font-size: 54px;
    line-height: 1;
  }
}

@media screen and (max-width: 479px) {
  body .h1 {
    letter-spacing: -2px;
    text-shadow: 0 0 6px #000;
    font-size: 40px;
    line-height: 0.9;
  }
}

.h1 {
  color: white;
  letter-spacing: -4px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Louis George Café', sans-serif;
  font-size: 6.3vw;
  font-weight: 700;
  line-height: 0.8;
}
@media screen and (max-width: 991px) {
  body .h2 {
    letter-spacing: 0;
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  body .h2 {
    line-height: 1.2;
  }
}

@media screen and (max-width: 479px) {
  body .h2 {
    text-shadow: 0 0 3px #000;
    font-size: 20px;
  }
}

.h2 {
  letter-spacing: -1px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Louis george Café', sans-serif;
  font-size: 28px;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
}

// h1 {
//   margin-block-start: 0.67em;
//   margin-block-end: 0.67em;
// }

.h3 {
  color: white;
  text-align: center;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Louis George Café', sans-serif;
  font-size: 60px;
  font-style: italic;
  font-weight: 300;
  line-height: 65px;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    letter-spacing: 0;
    font-size: 6vw;
  }
  @media screen and (max-width: 767px) {
    font-size: 7.5vw;
  }
  @media screen and (max-width: 479px) {
    font-size: 11vw;
  }
}

.h4 {
  opacity: 0.7;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 200;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  @media screen and (max-width: 991px) {
    font-size: 2vw;
  }

  @media screen and (max-width: 767px) {
    font-size: 2.5vw;
  }

  @media screen and (max-width: 479px) {
    font-size: 5vw;
    line-height: 1.1;
  }
}
.regular-btn {
  background: transparent;
  border: none;
  height: 78px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #fcdfac;
  text-decoration: none;
}
