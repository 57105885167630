.client-hub-single-game {
  margin-top: 450px;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (max-width: 767px) {
    margin-top: 300px;
    gap: 40px;
  }
  & .game-header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100vw;

    & .image {
      position: relative;
      z-index: 1;
      height: 550px;

      @media (max-width: 767px) {
        height: 350px;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% -40%;
      }
    }

    & .title {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.2);
      & h1 {
        position: absolute;
        z-index: 3;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-transform: uppercase;
        font-family: 'Resolve Medium';
        font-size: 64px;
        letter-spacing: 6px;

        @media (max-width: 767px) {
          font-size: 48px;
          letter-spacing: 2px;
          text-align: center;
        }
      }
    }
  }

  & .game-assets {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;

    @media (max-width: 840px) {
      flex-direction: column;
    }
    & .gallery {
      width: 550px;
      height: 600px;
      border-radius: 20px;

      @media (max-width: 840px) {
        width: 100%;
        height: calc(100vw - 240px);
        max-height: 700px;
      }
      @media (max-width: 767px) {
        width: 100%;
        height: calc(100vw - 60px);
        max-height: 500px;
      }

      & .swiper-container {
        padding: 0 !important;
        border-radius: 20px;
        height: 100%;
        width: 100%;
      }

      & .swiper-wrapper {
        width: 550px;
        height: 550px;

        @media (max-width: 1366px) {
          width: 300px;
          height: 300px;
        }
        @media (max-width: 840px) {
          width: 100%;
          height: 100%;
        }
      }

      & .swiper-slide {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }

    & .assets {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      & .download-box {
        width: 100%;
        background-color: #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 14px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease;
        text-decoration: none;
        color: #000;

        &:hover {
          box-shadow: 0px 4px 4px 0px #0000001a;

          & .title p {
            color: #ec3120;
          }
        }

        & .title {
          & p {
            color: #1b1b1b;
            margin: 0;
            font-family: 'Space Grotesk Bold';
            text-transform: uppercase;
            font-size: 22px;

            @media (max-width: 767px) {
              font-size: 18px;
            }
          }
        }

        & .icon {
          width: 24px;
          height: 24px;
          margin-top: -8px;

          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  & .game-about {
    display: flex;
    flex-direction: column;
    gap: 20px;
    & .title {
      & h2 {
        margin: 0;
        color: #ec3120;
        font-family: 'Space Grotesk Bold';
        font-size: 40px;

        @media (max-width: 767px) {
          font-size: 28px;
        }
      }
    }

    & .description {
      & p {
        margin: 0;
        font-family: 'Space Grotesk';
        font-size: 18px;
        color: #a8a8a8;

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }

    & .video {
      width: 100%;
      height: 450px;
      margin-top: 40px;

      @media (max-width: 767px) {
        height: 300px;
        margin-bottom: 40px;
      }

      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 32px;
      }
    }
  }
}
