.toka-header {
  width: 100%;
  height: 100px;
  margin: 40px 0px;
  z-index: 30;

  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }
  .mobile-view {
    @media screen and (max-width: 767px) {
      padding: 1rem !important;
    }
  }
  .toka-city-icon {
    padding-left: 5%;
    width: 248px;
    height: 142px;
    margin-bottom: 10%;
    @media screen and (max-width: 1350px) {
    margin-bottom: 5%;
    }
    @media screen and (max-width: 767px) {
      width: 124px;
      height: 72px;
      margin-bottom: 0;
      margin-top: 5% !important;
    }
    @media screen and (max-width: 421px) {
      margin-top: 15% !important;
    }
  }
  .menu-icon {
    width: 68px;
    height: 8px;
  }
  .menu-wrapper {
    cursor: pointer;
  }
  .text-menu {
    font-family: 'Louis George Café';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    height: fit-content;
  }
  .button-connect {
    padding: 0px 40px;
    height: 68px;
    background: #fcdfac;
    border-radius: 12321px;
    text-transform: uppercase;
    font-family: 'Louis George Café', sans-serif;
    font-size: 15px;
  }
  .button-connect.icon-btn {
    width: 55px;
    height: 55px;
    padding: 0px;
  }
  .button-connect.play {
    height: 55px;
    white-space: nowrap;
    padding: 0px 20px;
  }
  .full-menu-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: #1b1c26;
    width: 100%;
    height: 100%;
  }
  .header-menu {
    padding-left: 32px;
    font-family: 'Louis George Café';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    letter-spacing: -3px;
    color: #fcdfac;
  }
  .navigate-btn {
    font-family: 'Louis George Café';
    font-style: normal;
    font-weight: 700;
    font-size: 43px;
    letter-spacing: -0.05em;
    color: #ffffff;
    text-decoration: none;
  }
  .header-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.header1 {
  width: 100vw;
  height: 100vw;
}
.header2 {
  margin: 50px;
  width: 100%;
}

.promo-h1 {
  margin-top: 10%;
}

.glow-buttons {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.glow-png-prm {
  width: 230px;
  margin-top: -20px;
  margin-right: -25px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    margin-right: -30px;
  }
  @media screen and (max-width: 767px) {
    width: 170px;
    margin-right: 30px;
    margin-top: 20px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 10px;
  }
}
.glow-png-cta {
  width: 230px;
  margin-top: -20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: 170px;
    margin-right: 0;
    margin-top: -15px;
  }
}

.glow-png {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.glow-png-prm-txt {
  z-index: 1;
  color: #24624b;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;

  @media screen and (max-width: 767px) {
    font-size: 10px;
  }
}

.glow-png-txt {
  z-index: 1;
  color: #24624b;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .header-btns {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 420px) {
  .header-btns {
    margin-top: 20%;
  }
}

.play-btn-wrapper {
  width: 185.873px;
  height: 56px;
  @media screen and (max-width: 767px) {
    width: 135px !important;
    height: 30px !important;
  }
  position: relative;
  .play-text {
    position: absolute;
    top: 30%;
    left: 15%;
    z-index: 2;
    font-family: 'Louis George Café';
    color: #24624b;
    text-align: center;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    width: 130.327px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 767px) {
      width: 100px !important;
      font-size: 14px !important;
      left: 13% !important;
    }
  }
  .bg {
    position: absolute;
    width: 100%;
    flex-shrink: 0;
    top: -10px;

    @media screen and (max-width: 767px) {
      top: -15px;
    }

    img {
      width: 100%;
    }
  }
}

.profile-icon-button {
  background: transparent;
  border: none;
}

.close-button {
  & .close {
    background: url('../../images/dashboard/close_dashboard.svg') no-repeat;
    border: none;
    padding: 25px;
  }
}

.modal-dialog {
  border-radius: 20px;
}
.modal-body {
  display: flex;
  gap: 10px;
  border-radius: 20px;
}
