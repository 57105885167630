.loading-spinner {
  width: 100%;
  height: 100vh;
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #ffffff;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .loading-spinner h1 {
    font-size: 24px;
    line-height: 26px;
  }
}
