@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

/* Normalize.css
----------------------------------------------- */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
body {
  margin: 0;
  background: #ececec !important;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}
h3 {
  font-size: 1.17em;
  margin: 1em 0;
}
h4 {
  font-size: 1em;
  margin: 1.33em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}
h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 1em 40px;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
p,
pre {
  margin: 1em 0;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: '';
  content: none;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
dl,
menu,
ol,
ul {
  margin: 1em 0;
}
dd {
  margin: 0 0 0 40px;
}
menu,
ol,
ul {
  padding: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  margin-left: -7px;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
  overflow: visible;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  height: 13px;
  width: 13px;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.container-box-lg {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
}
.container-box-md {
  width: 100%;
  max-width: 1136px;
  margin: 0px auto;
  box-sizing: border-box;
}
.container-box-sm {
  width: 100%;
  max-width: 768px;
  margin: 0px auto;
  box-sizing: border-box;
}
.container-box-xs {
  width: 100%;
  max-width: 480px;
  margin: 0px auto;
  box-sizing: border-box;
}
.spacing-x-01 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.spacing-x-02 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.spacing-x-03 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.spacing-y-01 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (max-width: 1136px) {
  .spacing-y-01 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.spacing-y-02 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
@media (max-width: 1136px) {
  .spacing-y-02 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
.spacing-y-03 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 1136px) {
  .spacing-y-03 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.spacing-y-t-01 {
  padding-top: 1.25rem;
}
@media (max-width: 1136px) {
  .spacing-y-t-01 {
    padding-top: 0.25rem;
  }
}
.spacing-y-b-01 {
  padding-bottom: 1.25rem;
}
@media (max-width: 1136px) {
  .spacing-y-b-01 {
    padding-bottom: 0.25rem;
  }
}
.show-on-sm--block {
  display: block;
}
@media (min-width: 769px) {
  .show-on-sm--block {
    display: none;
  }
}
.show-on-md {
  display: block;
}
@media (min-width: 1137px) {
  .show-on-md {
    display: none;
  }
}
@media (max-width: 1136px) {
  .hide-md {
    display: none;
  }
}
@media (max-width: 768px) {
  .hide-sm {
    display: none;
  }
}
.brand-red--color {
  color: #e2001b;
}
.brand-red--back {
  background: #e2001b;
}
.brand-light--color {
  color: #eff1f7;
}
.brand-light--back {
  background: #eff1f7;
}
.font-family-01 {
  font-family: 'Resolve';
}
.font-family-02 {
  font-family: 'Space Grotesk';
}
.style-font-link {
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.global__heading {
  z-index: 1;
  position: relative;
  text-align: left;
}
@media (min-width: 1137px) {
  .global__heading.half-width {
    width: 50%;
  }
}
.global__heading.align-left-md-center {
  text-align: left;
}
@media (max-width: 1136px) {
  .global__heading.align-left-md-center {
    text-align: center;
  }
}
.global__heading.align-center {
  text-align: center;
}
.global__heading-title {
  margin: 0;
}
.global__heading-title.size-m {
  color: false;
  text-transform: uppercase;
  font-family: 'Resolve';
  font-size: 2.9875rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.29875rem;
}
@media (max-width: 1136px) {
  .global__heading-title.size-m {
    font-size: 1.4rem;
  }
}
.global__heading-title.size-l {
  color: false;
  text-transform: uppercase;
  font-family: 'Resolve';
  font-size: 2.4875rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.24875rem;
}
@media (max-width: 1136px) {
  .global__heading-title.size-l {
    font-size: 1.6rem;
  }
}
.global__heading-title.case-normal {
  text-transform: initial;
}
.global__heading-title.bold-normal {
  font-weight: 400;
}
.global__heading-subtitle {
  margin: 0;
  display: block;
  margin-top: 0.69rem;
}
.global__heading-subtitle.size-m {
  color: false;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.15rem;
  font-weight: 500;
}
@media (max-width: 1136px) {
  .global__heading-subtitle.size-m {
    font-size: 1rem;
  }
}
.global__heading-subtitle.size-l {
  color: false;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 2rem;
  font-weight: 400;
  line-height: 112.5%;
  letter-spacing: 0.2rem;
  font-weight: 500;
}
@media (max-width: 1136px) {
  .global__heading-subtitle.size-l {
    font-size: 1.5rem;
  }
}
.global__heading-subtitle.case-normal {
  text-transform: initial;
}
.global__heading-subtitle.bold-normal {
  font-weight: 400;
}
.global__heading-text {
  margin: 0;
  margin-top: 0.8em;
  text-align: left;
}
.global__heading-text.size-m {
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
}
@media (max-width: 1136px) {
  .global__heading-text.size-m {
    font-size: 0.8rem;
  }
}
.global__heading-text.size-l {
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
}
@media (max-width: 1136px) {
  .global__heading-text.size-l {
    font-size: 0.9rem;
  }
}
.global__heading-text.case-normal {
  text-transform: initial;
}
.global__heading-text.bold-normal {
  font-weight: 400;
}
.global__heading-text.bold-light {
  font-weight: 300;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-backdrop.show {
  opacity: 0.4;
}
.modal-dialog {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  animation: fadein 0.2s ease-in forwards;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.35);
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
    margin-right: unset;
    margin-left: unset;
  }
}
.modal-content {
  display: flex;
  flex-direction: row;
  background: #10111a;
  max-width: unset !important;
  width: fit-content;
  border-radius: 26px;
}
@media (max-width: 1200px) {
  .modal-content {
    width: 92vw;
  }
}
.modal-body {
  border-radius: 26px;
  height: 60vh;
  width: 55vw;
  padding: 0;
}
@media (max-width: 1200px) {
  .modal-body {
    height: 40vh;
    width: 90vw;
  }
}
@media (max-width: 767px) {
  .modal-body {
    height: 25vh;
    width: 90vw;
  }
}
@media (max-width: 1200px) and (orientation: landscape) {
  .modal-body {
    height: 80vh;
    width: 70vw;
  }
}
.modal-body iframe {
  border-radius: 26px;
  width: 100%;
  height: 100%;
}
body:has(dialog[open]) {
  overflow: hidden;
}
.dmodal {
  width: 100%;
  max-width: 1136px;
  margin: 0px auto;
  box-sizing: border-box;
  border-radius: 1.875rem;
  padding: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  border: 0;
  background: #10111a;
}
@media (max-width: 1136px) {
  .dmodal {
    max-width: 90%;
  }
}
.dmodal:focus {
  outline: none;
}
.dmodal[open],
.dmodal::backdrop {
  animation: fadein 0.2s ease-in forwards;
  backdrop-filter: blur(5px);
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.35);
}
.dmodal.with-video::backdrop {
  backdrop-filter: blur(29px);
  background: rgba(16, 17, 26, 0.2);
}
.dmodal__close {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vw;
  line-height: 100%;
  padding: 0.69rem;
  border: 0;
  cursor: pointer;
  background: transparent;
  border: 1px solid white;
  color: white;
  transition: all 0.2s;
  z-index: 999;
}
.dmodal__close svg {
  width: 0.75rem;
}
.dmodal__close:hover {
  background: #e2001b;
}
.dmodal__wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
}
.dmodal__wrapper::after {
  content: '';
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;
  z-index: -1;
  filter: blur(4px);
}
.dmodal__content {
  padding: 3.125rem;
  display: grid;
  grid-gap: 1.8em;
}
@media (max-width: 1136px) {
  .dmodal__content {
    padding: 2.125rem;
  }
}
@media (min-width: 1137px) {
  .dmodal__content {
    padding-right: 0;
  }
}
.modal-content.video {
  width: 60vw;
  height: 100%;
  aspect-ratio: 16 / 9;
  padding: 0;
}
.dmodal__content iframe {
  width: 100%;
  height: 100%;
}
.dmodal__content__form {
  display: flex;
  flex-direction: column;
}
.dmodal__content__form-field span {
  color: white;
  font-family: 'Resolve';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
  margin: 0.94rem 0;
  display: block;
}
@media (max-width: 1136px) {
  .dmodal__content__form-field span {
    font-size: 0.8rem;
  }
}
.dmodal__content__form-field input,
.dmodal__content__form-field textarea {
  color: white;
  border-radius: 0.625rem;
  outline: 0;
  border: 1px solid #fff;
  background: linear-gradient(271deg, rgba(247, 247, 247, 0.8) -55.63%, rgba(247, 247, 247, 0) 102.11%);
  margin: 0;
  padding: 0.8em 0.5em;
  box-sizing: border-box;
  width: 100%;
}
.dmodal__content__form-field input:placeholder-shown,
.dmodal__content__form-field textarea:placeholder-shown {
  border: 1px solid #fff;
}
.dmodal__content__form-field textarea {
  min-height: 8rem;
}
.dmodal__content__form-btn {
  border: 0;
  width: fit-content;
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: #e2001b;
  color: #fff;
  margin: 0;
  margin-top: 2rem;
}
@media (max-width: 1136px) {
  .dmodal__content__form-btn {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .dmodal__content__form-btn {
    color: false;
    font-family: 'Space Grotesk';
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .dmodal__content__form-btn {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .dmodal__content__form-btn {
    padding: 1.2em 1.41em;
  }
}
.dmodal__content__form-btn:focus {
  outline: none;
  border: 0;
}
.dmodal__content__form-btn span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.dmodal__content__form-btn:hover {
  background: #fff;
  color: #e2001b;
}
@media (max-width: 1136px) {
  .dmodal__content__form-btn {
    width: 100%;
  }
}
.dmodal__image {
  position: relative;
}
@media (max-width: 1136px) {
  .dmodal__image {
    display: none;
  }
}
.dmodal__image--placeholder {
  width: 29.5625em;
  height: 100%;
  pointer-events: none;
}
.dmodal__image--real {
  width: 43.5625em;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.dmodal__deco-01 {
  position: absolute;
  width: 2rem;
  top: 2rem;
  left: 2rem;
}
.pulsebtn {
  animation: pulse-animation 0.8s infinite alternate ease-in-out;
}
@keyframes pulse-animation {
  0% {
    filter: drop-shadow(0px 0px 0px #fff);
    transform: scale(1);
  }
  100% {
    filter: drop-shadow(0px 0px 13.71px #fff);
    transform: scale(1.2);
  }
}
html {
  font-family: sans-serif;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}
@media (min-width: 1137px) {
  html {
    scroll-padding-top: 75px;
  }
}
@media (max-width: 1136px) {
  html {
    scroll-padding-top: 65px;
  }
}
.nav {
  position: fixed;
  top: 0;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto;
  width: 100%;
}
.nav__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
}
.nav__wrapper__bar {
  border-radius: 0rem 0rem 1.875rem 1.875rem;
  background: rgba(16, 17, 26, 0.5);
  backdrop-filter: blur(10px);
}
@media (max-width: 1440px) {
  .nav__wrapper__bar {
    border-radius: 0rem;
  }
}
.nav__wrapper__bar__inner {
  max-width: 1250px;
  margin: 0 auto;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1440px) {
  .nav__wrapper__bar__inner {
    padding: 0.84rem 2.72rem;
  }
}
.nav__logo {
  display: flex;
}
.nav__logo img:nth-child(1) {
  width: 4.501em;
}
@media (max-width: 1136px) {
  .nav__logo img:nth-child(1) {
    width: 2.3125rem;
  }
}
.nav__logo img:nth-child(2) {
  margin-left: 0.8em;
  width: 12.32088em;
}
@media (max-width: 1136px) {
  .nav__logo img:nth-child(2) {
    display: none;
  }
}
.nav__links--item {
  color: #fff;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 3.44rem;
}
@media (max-width: 1136px) {
  .nav__links--item {
    font-size: 0.9rem;
  }
}
.nav__links--item:last-child {
  margin-right: 0;
}
@media (max-width: 1440px) {
  .nav__links--item {
    margin-right: 2.04rem;
  }
}
@media (max-width: 1136px) {
  .nav__links--item {
    display: none;
  }
}
.nav__links--button {
  color: false;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: #e2001b;
  color: #fff;
}
@media (max-width: 1136px) {
  .nav__links--button {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .nav__links--button {
    color: false;
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .nav__links--button {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .nav__links--button {
    padding: 1.2em 1.41em;
  }
}
.nav__links--button:focus {
  outline: none;
  border: 0;
}
.nav__links--button span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.nav__links--button:hover {
  background: #fff;
  color: #e2001b;
}
@media (max-width: 1136px) {
  .nav__links--button {
    padding: 0.6em 1.41em;
  }
}
.hero {
  height: 100vh;
  position: relative;
  border-radius: 0rem 0rem 24px 24px;
  overflow: hidden;
  max-height: 1000px;
  z-index: 6;
}
@media (max-width: 1136px) {
  .hero {
    font-size: 7px;
    max-height: 530px;
  }
}
@media (max-width: 768px) {
  .hero {
    font-size: 4px;
  }
}
.hero__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.hero__content__text {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Resolve';
}
.hero__content__text--line1 {
  color: #eff1f7;
  text-shadow: 0px 0px 2em #ff8c21;
  display: block;
  white-space: nowrap;
  font-size: 2.67744em;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 1.87419rem;
}
@media (max-width: 1136px) {
  .hero__content__text--line1 {
    font-size: 2.67744em;
    letter-spacing: 0.57419rem;
  }
}
.hero__content__text--line2 {
  color: #fff;
  text-shadow: 0 0 0.6em #cd2c1e;
  display: block;
  font-size: 7.36288em;
  font-weight: 500;
  line-height: 109.091%;
  letter-spacing: 0.73631rem;
}
@media (max-width: 1136px) {
  .hero__content__text--line2 {
    letter-spacing: 0.53631rem;
  }
}
.about {
  background: #e2001b;
  margin-top: -1.875rem;
  padding-top: 1.875rem;
  padding-bottom: 1.8rem;
  border-radius: 0rem 0rem 24px 24px;
  position: relative;
  z-index: 5;
}
.about__wrapper__row1 {
  display: flex;
  align-items: center;
}
.about__wrapper__row1__box {
  padding: 3.31rem 3.05rem;
}
@media (min-width: 1137px) {
  .about__wrapper__row1__box {
    max-width: 50%;
  }
}
@media (max-width: 1136px) {
  .about__wrapper__row1__box {
    padding: 1.31rem 1.05rem;
  }
}
.about__wrapper__row2 {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 1136px) {
  .about__wrapper__row2 {
    flex-direction: column-reverse;
  }
}
.about__wrapper__row2__box {
  padding: 3.31rem 3.05rem;
  position: relative;
  border-radius: 1.25rem;
  background: linear-gradient(104deg, rgba(217, 217, 217, 0.2) 26.11%, rgba(217, 217, 217, 0) 115.63%);
  backdrop-filter: blur(25px);
}
@media (max-width: 1136px) {
  .about__wrapper__row2__box {
    padding: 2.31rem 2.05rem;
    transform: translateY(100px);
    margin-top: -100px;
    position: relative;
    z-index: 20;
  }
}
.about__wrapper__row2__box::before {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
@media (max-width: 1136px) {
  .about__wrapper__row2__box::before {
    background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  }
}
.about__char {
  font-size: 16px;
  line-height: 0;
}
@media (min-width: 1137px) {
  .about__char {
    margin-top: -16em;
  }
}
@media (max-width: 1136px) {
  .about__char {
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .about__char {
    font-size: 8px;
  }
}
.about__char__wrapper {
  position: relative;
}
.about__char__image--placeholder {
  width: 35em;
}
.about__char__image--real {
  width: 35em;
  position: absolute;
  left: 0;
  bottom: -4.6%;
}
.about__char__button {
  position: absolute;
  right: 8.3em;
  bottom: 11em;
}
.about__char__button--link {
  background: #e2001b;
  color: white;
  width: fit-content;
  border-radius: 100vw;
  display: inline-flex;
  padding: 1em;
  transition: transform 0.4s;
  border: 0;
  outline: 0;
}
.about__char__button--link svg {
  animation: pulse-animation 0.8s infinite alternate ease-in-out;
  width: 4.375em;
}
.about__char__button--link:hover {
  transform: scale(1.1);
}
.about__char__button--link:hover svg {
  animation-play-state: paused;
}
.features {
  background: #10111a;
  margin-top: -1.875rem;
  padding-top: 4.375rem;
  border-radius: 0rem 0rem 1.875rem 1.875rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 1136px) {
  .features {
    padding-top: 125px;
  }
}
.features__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  padding-bottom: 1.25rem;
}
.features__row {
  display: grid;
  align-items: center;
}
.features__row.row-1 {
  grid-template-columns: 1fr 2fr;
}
@media (max-width: 1136px) {
  .features__row.row-1 {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.features__row.row-2 {
  grid-template-columns: 2fr 1fr;
}
@media (max-width: 1136px) {
  .features__row.row-2 {
    grid-template-columns: 1fr;
  }
}
.row-1 .left {
  position: relative;
  padding-top: 50px;
}
.row-1 .features__row__wave {
  background: #fff;
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 15px 20px;
  width: 30%;
  border-radius: 7.06px;
  position: absolute;
  top: -2.5%;
  left: 9%;
  z-index: 3;
}
.row-1 .features__row__wave .new-players {
  margin: 0;
}
.row-1 .features__row__wave .new-players p {
  margin: 0;
  font-weight: 300;
}
.row-1 .features__row__wave .numbers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-1 .features__row__wave .numbers .first {
  font-weight: 700;
}
.row-1 .features__row__wave .numbers .second {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
  color: #17baff;
  font-weight: 700;
}
.row-1 .features__row__wave .progress-bar1 {
  width: 100%;
  height: 10px;
}

.row-1 .features__row__wave .progress-bar1 .progress-container {
  width: 100%;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
}

.row-1 .features__row__wave .progress-bar1 .bar {
  height: 100%;
  background-color: #17baff;
  width: 0%; /* Start width at 0% */
  transition: width 3s ease-in-out; /* Use transition for smooth width change */
}

.row-1 .features__row__wave .progress-bar1 .bar.animate {
  width: 80%; /* Target width when animation is triggered */
}
.row-1 .features__row__acquisition {
  background: #fff;
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 20px;
  width: 28%;
  border-radius: 7.06px;
  position: absolute;
  right: 4%;
  bottom: -4%;
  z-index: 5;
}
.row-1 .features__row__acquisition .cost {
  margin: 0;
}
.row-1 .features__row__acquisition .cost p {
  margin: 0;
  font-weight: 700;
}
.row-1 .features__row__acquisition .numbers {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.row-1 .features__row__acquisition .numbers .second {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
  color: #e2001b;
  font-weight: 700;
}
.row-1 .features__row__image_bg {
  width: 40%;
  left: 30%;
  top: 16%;
  position: absolute;
  z-index: 2;
  border-radius: 12px;
}
.row-1 .features__row__image_bg img {
  width: 100%;
  border-radius: 12px;
}
.row-1 .features__row__image {
  min-width: 44.125em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.row-1 .overlay {
  position: absolute;
  z-index: 4;
  top: 16%;
  left: 30%;
  width: 40%;
  height: 84%;
  backdrop-filter: blur(5px);
  border-radius: 15px;
}
@media (max-width: 1136px) {
  .row-1 .features__row__image {
    min-width: auto;
    margin: 0 auto;
  }

  .row-1 .features__row__wave {
    left: 0;
    top: 5%;
    gap: 4px;
    padding: 5px 10px;
    width: 35%;
    font-size: 10px;
  }

  .row-1 .features__row__acquisition {
    gap: 6px;
    padding: 5px 10px;
    width: 30%;
    right: 2%;
    bottom: -2%;
    font-size: 10px;
  }

  .row-1 .features__row__image_bg {
    top: 24%;
  }

  .row-1 .features__row__wave .progress-bar1 {
    height: 8px;
  }
  .row-1 .features__row__wave .progress-bar1 .progress-container {
    height: 8px;
  }

  .row-1 .overlay {
    top: 23%;
    height: 77%;
    border-radius: 10px;
  }
}
.row-1 .features__row__image img {
  display: block;
  max-width: 40%;
}
@media (max-width: 1136px) {
  .row-1 .features__row__image img {
    min-width: auto;
    margin: 0 auto;
  }
}
@media (max-width: 1136px) {
  .row-2 .features__row__image {
    order: 1;
  }
}
.row-2 .features__row__image video,
.row-2 .features__row__image img {
  display: block;
  min-width: 42em;
  max-width: 100%;
}
@media (max-width: 1136px) {
  .row-2 .features__row__image video,
  .row-2 .features__row__image img {
    min-width: auto;
    margin: 0 auto;
  }
}
@media (min-width: 1441px) {
  .row-1 .features__row__text {
    margin-left: -5em;
  }
}
@media (max-width: 1136px) {
  .row-2 .features__row__text {
    order: 2;
  }
}
.features__row__text__icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: space-between;
}
@media (max-width: 1440px) {
  .features__row__text__icons {
    flex-direction: column;
  }
}
.features__row__text__icons--item {
  text-align: center;
}
@media (max-width: 1440px) {
  .features__row__text__icons--item {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .features__row__text__icons--item:last-child {
    margin-bottom: 0;
  }
}
.features__row__text__icons--item img {
  width: 3.75rem;
  margin-bottom: 1.2rem;
}
@media (max-width: 1440px) {
  .features__row__text__icons--item img {
    margin-bottom: 0rem;
    margin-right: 1.2rem;
    width: 2.75rem;
  }
}
.features__row__text__icons--item span {
  color: #d9d9d9;
  font-family: 'Space Grotesk';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
  display: block;
  font-weight: 700;
}
@media (max-width: 1136px) {
  .features__row__text__icons--item span {
    font-size: 0.8rem;
  }
}
.products__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  margin-bottom: 2.5rem;
}
.expandcard {
  z-index: 1;
  position: relative;
}
.expandcard_acc {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 540px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 1136px) {
  .expandcard_acc {
    flex-direction: column;
    height: auto;
    gap: 0.5rem;
  }
}
.expandcard_acc .expandcard_text {
  line-height: 0;
}
@media (min-width: 1137px) {
  .expandcard_acc .expandcard_text {
    display: none;
  }
}
.expandcard_acc__item {
  width: 150px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-radius: 80px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1136px) {
  .expandcard_acc__item {
    width: 100%;
    height: 75px;
  }
  .expandcard_acc__item.active {
    height: 600px;
  }
}
.expandcard_acc__item:hover .expandcard_acc__item--back {
  filter: blur(0px);
}
.expandcard_acc__item.active {
  cursor: initial;
  border-radius: 35px;
}
.expandcard_acc__item--back {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
  transition: filter 0.2s;
}
.active .expandcard_acc__item--back {
  filter: blur(0px);
}
.expandcard_acc__item__inner {
  background: linear-gradient(0deg, #1c252e 0%, rgba(0, 0, 0, 0) 83.22%);
  overflow: hidden;
  width: 100%;
  position: relative;
}
.expandcard_acc__item__title {
  position: absolute;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 0.5em;
  transition: all 0.5s ease;
  padding: 1.2em 1.63em;
}
@media (min-width: 1137px) {
  .expandcard_acc__item__title {
    bottom: 0;
    left: 50%;
    transform: rotate(-90deg) translate(0, 0);
    transform-origin: 0;
  }
  .active .expandcard_acc__item__title {
    transform: rotate(0deg) translateX(0%);
    white-space: normal;
    writing-mode: unset;
    left: 0;
    bottom: 0;
    top: initial;
  }
}
@media (max-width: 1136px) {
  .expandcard_acc__item__title {
    top: 50%;
    left: 0;
    transform: rotate(0deg) translateY(-50%);
    transform-origin: 0 0;
  }
  .active .expandcard_acc__item__title {
    top: 100%;
    transform: rotate(0deg) translateY(-100%);
  }
}
.expandcard_acc__item__title span {
  white-space: nowrap;
  color: #eff1f7;
  text-transform: uppercase;
  font-family: 'Resolve';
  font-size: 2.4875rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.24875rem;
}
@media (max-width: 1136px) {
  .expandcard_acc__item__title span {
    font-size: 1.6rem;
  }
}
@media (max-width: 1136px) {
  .expandcard_acc__item__title span {
    font-size: 1.2rem;
  }
}
.expandcard_acc__item__title p {
  color: #eff1f7;
  font-family: 'Resolve';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  font-weight: 300;
  display: none;
  margin: 0;
}
@media (max-width: 1136px) {
  .expandcard_acc__item__title p {
    font-size: 0.9rem;
  }
}
.active .expandcard_acc__item__title p {
  display: block;
}
.expandcard_acc__item__title img {
  width: 3.5rem;
}
@media (min-width: 1137px) {
  .expandcard_acc__item__title img {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }
  .active .expandcard_acc__item__title img {
    transform: rotate(90deg);
  }
}
@media (max-width: 1136px) {
  .expandcard_acc__item__title img {
    transform: rotate(90deg);
    width: 3rem;
  }
}
.expandcard_acc__item.active {
  flex-grow: 1;
  transition: all 0.5s ease;
}
.tech {
  border-radius: 24px;
  background: url('../src/images/redesign/back-tech.png');
  background-size: cover;
}
.tech__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
}
.tech__text {
  width: 100%;
  max-width: 1136px;
  margin: 0px auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}
@media (max-width: 1136px) {
  .tech__text {
    grid-template-columns: 1fr;
  }
}
.tech__text--logos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1136px) {
  .tech__text--logos {
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.25rem;
  }
}
.tech__text--logos img {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
@media (max-width: 1136px) {
  .tech__text--logos img {
    max-width: 150px;
  }
}
@media (min-width: 1137px) {
  .tech__text--logos img:nth-child(1) {
    max-width: 12.9375em;
  }
}
@media (min-width: 1137px) {
  .tech__text--logos img:nth-child(2) {
    max-width: 17.4375em;
    margin-top: 1.62rem;
  }
}
@media (max-width: 1136px) {
  .tech__text--logos img:nth-child(2) {
    margin-left: 1.62rem;
  }
}
.tech__box {
  padding: 3.31rem 3.05rem;
  position: relative;
  border-radius: 1.25rem;
  background: linear-gradient(104deg, rgba(217, 217, 217, 0.2) 26.11%, rgba(217, 217, 217, 0) 115.63%);
  backdrop-filter: blur(25px);
}
@media (max-width: 1136px) {
  .tech__box {
    transform: translateY(50px);
    margin-top: -50px;
  }
}
.tech__box::before {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(90deg, rgba(228, 225, 225, 0.5), rgba(217, 217, 217, 0.1));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
@media (max-width: 1136px) {
  .tech__box::before {
    background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  }
}
.tech__box__in {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  z-index: 2;
  position: relative;
}
@media (max-width: 1136px) {
  .tech__box__in {
    grid-template-columns: 1fr;
  }
}
.tech__box__in--item {
  padding: 2em 4em;
}
@media (min-width: 1137px) {
  .tech__box__in--item {
    border-right: 2px solid #d9d9d9;
  }
}
@media (max-width: 1136px) {
  .tech__box__in--item {
    padding: 2em 2em;
    position: relative;
    text-align: center;
  }
  .tech__box__in--item::after {
    content: '';
    height: 2px;
    background-color: #d9d9d9;
    width: 200px;
    margin: 0 auto;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
  .tech__box__in--item:last-child::after {
    height: 0px;
  }
}
.tech__box__in--item:last-child {
  border-right: 0;
}
.tech__box__in--item img {
  width: 100%;
  max-width: 4.5em;
}
.tech__box__in--item span {
  color: #d9d9d9;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
  margin-top: 1em;
  display: block;
  width: 100%;
}
@media (max-width: 1136px) {
  .tech__box__in--item span {
    font-size: 1rem;
  }
}
.tech__box__in--item p {
  margin: 0;
  margin-top: 0.8em;
  color: #d9d9d9;
  font-family: 'Space Grotesk';
  font-weight: 300;
}
.dashboard__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (max-width: 1136px) {
  .dashboard__wrapper {
    flex-direction: column;
  }
}
@media (max-width: 1136px) {
  .dashboard__demo {
    order: 2;
    text-align: center;
  }
}
.dashboard__demo img {
  width: 100%;
  max-width: 48.12969rem;
}
.dashboard__demo--note {
  color: #8b95aa;
  font-family: 'Space Grotesk';
  display: block;
  font-weight: 300;
  margin-top: 10px;
}
.dashboard__text {
  margin-top: 2rem;
  margin-left: 3rem;
}
@media (max-width: 1136px) {
  .dashboard__text {
    min-height: 33vh;
    margin: 0;
  }
}
.dashboard__text--header {
  display: block;
  color: #e2001b;
  text-transform: uppercase;
  font-family: 'Resolve';
  font-size: 2.4875rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.24875rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
@media (max-width: 1136px) {
  .dashboard__text--header {
    font-size: 1.6rem;
  }
}
.dashboard__text--list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.1em;
}
@media (max-width: 1136px) {
  .dashboard__text--list {
    gap: 1.5em;
  }
}
.dashboard__text--list-item {
  display: grid;
  grid-template-columns: 2em auto;
  align-items: center;
  grid-gap: 1.1em;
  padding: 0;
}
@media (max-width: 1136px) {
  .dashboard__text--list-item {
    grid-template-columns: 1.8em auto;
  }
}
.dashboard__text--list-item span {
  color: #10111a;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
  display: block;
}
@media (max-width: 1136px) {
  .dashboard__text--list-item span {
    font-size: 1rem;
  }
}
.awards {
  position: relative;
  z-index: 5;
}
.awards__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  margin-bottom: 9.75rem;
}
@media (max-width: 1136px) {
  .awards__wrapper {
    margin-bottom: auto;
  }
}
.awards__text {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1136px) {
  .awards__text {
    flex-direction: column;
  }
}
.awards__text__image {
  position: relative;
}
@media (max-width: 1136px) {
  .awards__text__image {
    text-align: center;
  }
}
.awards__text__image--placeholder {
  width: 48.75rem;
  height: 100px;
  pointer-events: none;
}
@media (max-width: 1440px) {
  .awards__text__image--placeholder {
    width: 40.625rem;
  }
}
@media (max-width: 1136px) {
  .awards__text__image--placeholder {
    display: none;
  }
}
.awards__text__image--real {
  width: 48.75rem;
  pointer-events: none;
}
@media (max-width: 1440px) {
  .awards__text__image--real {
    width: 40.625rem;
  }
}
@media (min-width: 1137px) {
  .awards__text__image--real {
    position: absolute;
    right: 0;
    top: -22%;
  }
}
@media (max-width: 1136px) {
  .awards__text__image--real {
    max-width: 24.375rem;
    width: 100%;
  }
}
.awards__btns {
  width: max-content;
}
@media (max-width: 1136px) {
  .awards__btns {
    margin: 0 auto;
  }
}
.awards__btns--link1 {
  width: 100%;
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: #e2001b;
  color: #fff;
  margin: 0;
  display: block;
  box-sizing: border-box;
}
@media (max-width: 1136px) {
  .awards__btns--link1 {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .awards__btns--link1 {
    color: false;
    font-family: 'Space Grotesk';
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .awards__btns--link1 {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .awards__btns--link1 {
    padding: 1.2em 1.41em;
  }
}
.awards__btns--link1:focus {
  outline: none;
  border: 0;
}
.awards__btns--link1 span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.awards__btns--link1:hover {
  background: #fff;
  color: #e2001b;
}
.awards__btns--link2 {
  border: 0;
  width: 100%;
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: transparent;
  color: #e2001b;
  border: 0.12em solid #e2001b;
  margin: 0;
  margin-top: 1rem;
  box-sizing: border-box;
  display: block;
}
@media (max-width: 1136px) {
  .awards__btns--link2 {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .awards__btns--link2 {
    color: false;
    font-family: 'Space Grotesk';
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .awards__btns--link2 {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .awards__btns--link2 {
    padding: 1.2em 1.41em;
  }
}
.awards__btns--link2:focus {
  outline: none;
  border: 0;
}
.awards__btns--link2 span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.awards__btns--link2:hover {
  background: #e2001b;
  color: #fff;
}
.feedback {
  background: #1b252f;
  margin-bottom: 10vh;
  width: 100vw;
}
.feedback__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2em;
  align-items: center;
  position: relative;
}
@media (max-width: 1136px) {
  .feedback__wrapper {
    grid-template-columns: 1fr;
    padding-bottom: 1vh;
  }
  .feedback {
    border-radius: 24px;
  }
}
.feedback__col1 {
  max-width: 550px;
  width: 35vw;
  height: 100%;
}
.feedback__col2 {
  max-width: 1440px;
  width: 93vw;
  height: 100%;
  margin-top: -150px;
}
.feedback__col2 .feedback_assets {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
}
.feedback__col2 .feedback_assets.show-on-md {
  flex-direction: column;
  align-items: center;
}
.feedback__col2 .feedback_assets img {
  object-fit: contain;
}
.feedback__col2 .feedback_assets video {
  height: 59vh;
  border-radius: 14px;
  margin-top: -20px;
  box-shadow: 7.5px 3px 30px 0px #00000029;
}
.feedback__col2 .feedback_assets.show-on-md video {
  height: 68vh;
  margin-top: 20px;
}
.feedback__col2 .recommend {
  background: #fff;
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-radius: 8px;
  position: absolute;
  left: 24%;
  bottom: -7%;
  box-shadow: 7.5px 3px 30px 0px #00000029;
}
.feedback__col2 .recommend .left p {
  margin: 0;
  font-size: 24px;
  font-family: 'Space Grotesk';
  font-weight: 700;
  line-height: 1;
}
.feedback__col2 .recommend .left span {
  margin: 0;
  font-size: 15px;
  font-family: 'Space Grotesk';
  font-weight: 800;
}
.feedback__col2 .recommend .right span {
  margin: 0;
  font-size: 24px;
  font-family: 'Space Grotesk';
  font-weight: 800;
}
.feedback__col2 img {
  margin-top: -100px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1136px) {
  .feedback__col1 {
    width: 80vw;
    margin-bottom: 2vh;
  }
  .feedback__col2.margin {
    padding-bottom: 18vh;
  }
  .feedback__col2 img {
    max-width: 400px;
    object-fit: contain;
    margin: 0 auto;
  }

  .feedback__col2 .recommend {
    width: 60%;
    left: 12%;
    bottom: -3%;
    padding: 10px 20px;
  }
}
.bookf__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
}
@media (max-width: 1136px) {
  .bookf__wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.bookf__box {
  border-radius: 1.875rem;
  background: #e2001b;
  background-image: url('../src/images/redesign/bookform-02.png');
  background-position: 108% 112%;
  background-repeat: no-repeat;
  background-size: 40.3125em auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
@media (max-width: 1136px) {
  .bookf__box {
    grid-template-columns: 1fr;
  }
}
.bookf__box__in {
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1136px) {
  .bookf__box__in {
    padding: 2.125rem;
  }
}
@media (min-width: 1137px) {
  .bookf__box__in {
    padding-right: 0;
  }
}
.bookf__box__form {
  display: flex;
  flex-direction: column;
}
.bookf__box__form-field span {
  color: white;
  font-family: 'Resolve';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
  margin: 0.94rem 0;
  display: block;
}
@media (max-width: 1136px) {
  .bookf__box__form-field span {
    font-size: 0.8rem;
  }
}
.bookf__box__form-field input,
.bookf__box__form-field textarea {
  color: white;
  border-radius: 0.625rem;
  outline: 0;
  border: 1px solid #fff;
  background: linear-gradient(271deg, rgba(247, 247, 247, 0.8) -55.63%, rgba(247, 247, 247, 0) 102.11%);
  margin: 0;
  padding: 0.8em 0.5em;
  box-sizing: border-box;
  width: 100%;
  backdrop-filter: blur(6px);
}
.bookf__box__form-field input:placeholder-shown,
.bookf__box__form-field textarea:placeholder-shown {
  border: 1px solid #fff;
  color: #eff1f7;
}
.bookf__box__form-field textarea {
  min-height: 8rem;
}
.bookf__box__form-btn {
  border: 0;
  width: fit-content;
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: #e2001b;
  color: #fff;
  background: #10111a;
  margin: 0;
  margin-top: 2rem;
}
@media (max-width: 1136px) {
  .bookf__box__form-btn {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .bookf__box__form-btn {
    color: false;
    font-family: 'Space Grotesk';
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .bookf__box__form-btn {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .bookf__box__form-btn {
    padding: 1.2em 1.41em;
  }
}
.bookf__box__form-btn:focus {
  outline: none;
  border: 0;
}
.bookf__box__form-btn span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.bookf__box__form-btn:hover {
  background: #fff;
  color: #e2001b;
}
@media (max-width: 1136px) {
  .bookf__box__form-btn {
    width: 100%;
  }
}
.bookf__box__image {
  position: relative;
  margin-top: -100px;
}
@media (max-width: 1136px) {
  .bookf__box__image {
    display: none;
  }
}
.bookf__box__image--placeholder {
  width: 40.3125em;
  height: 100%;
  pointer-events: none;
}
.bookf__box__image--real {
  width: 40.3125em;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.back-dark {
  background: #10111a;
  margin-top: -1.875rem;
  padding-top: 1.875rem;
  border-radius: 24px 24px 0rem 0rem;
  background-image: url('../src/images/redesign/team-back.png');
  background-size: 103.5625rem 119.09669rem;
  background-repeat: no-repeat;
  background-position: right center;
  z-index: 2;
  position: relative;
}
.team__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
@media (max-width: 1136px) {
  .team__wrapper {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
.team__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team__heading__controls {
  display: flex;
  align-items: center;
}
.team__heading__controls--prev,
.team__heading__controls--next {
  cursor: pointer;
  line-height: 0;
  color: #eff1f7;
  border-radius: 100vw;
  outline: none;
  background: linear-gradient(104deg, rgba(217, 217, 217, 0.2) 26.11%, rgba(217, 217, 217, 0) 115.63%);
  backdrop-filter: blur(25px);
}
.team__heading__controls--prev::before,
.team__heading__controls--next::before {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  border-radius: 100vw;
  padding: 2px;
  background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.team__heading__controls--prev svg,
.team__heading__controls--next svg {
  width: 35px;
}
.team__heading__controls--prev {
  margin-right: 1rem;
}
.swiper-container {
  padding: 0 6vw !important;
}
.team__slider {
  margin-top: 2rem;
  overflow: hidden;
  padding: 0 3rem;
}
.team__slider__card {
  position: relative;
  aspect-ratio: 2/2.5;
  border-radius: 30px;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(27, 37, 47, 0.5) 6.4%,
    rgba(27, 37, 47, 0.5) 6.41%,
    rgba(139, 149, 170, 0.9) 56.68%
  );
  transform-style: preserve-3d;
}
.team__slider__card---overlay {
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -1%;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(27, 37, 47, 0.8) 6.4%,
    rgba(27, 37, 47, 0.8) 6.41%,
    rgba(27, 37, 47, 0) 56.68%
  );
}
.team__slider__card--person {
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.team__slider__card--name {
  padding: 1.5rem 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  box-sizing: border-box;
}
.team__slider__card--name span {
  display: block;
  color: #e2001b;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
}
@media (max-width: 1136px) {
  .team__slider__card--name span {
    font-size: 1rem;
  }
}
.team__slider__card--name i {
  margin-top: 0.2rem;
  font-style: normal;
  text-transform: uppercase;
  display: block;
  color: #fff;
  font-family: 'Space Grotesk';
}
.team__slider__card--btn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  line-height: 0;
  color: rgba(239, 241, 247, 0.8);
  border-radius: 100vw;
  outline: none;
  background: linear-gradient(104deg, rgba(217, 217, 217, 0.2) 26.11%, rgba(217, 217, 217, 0) 115.63%);
  backdrop-filter: blur(25px);
  display: inline-block;
}
.team__slider__card--btn::before {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  border-radius: 100vw;
  padding: 1px;
  background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.team__slider__card--btn svg {
  width: 35px;
}
.team__slider__card__back {
  background: #e2001b;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  z-index: 6;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  overflow-y: auto;
}
@media (max-width: 1136px) {
  .team__slider__card__back {
    padding: 1rem 1rem;
  }
}
.active .team__slider__card__back {
  transform: rotateY(0deg);
}
.team__slider__card__back span {
  display: block;
  color: #10111a;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
}
@media (max-width: 1136px) {
  .team__slider__card__back span {
    font-size: 1rem;
  }
}
.team__slider__card__back i {
  margin-top: 0.2rem;
  font-style: normal;
  display: block;
  color: #fff;
  font-family: 'Space Grotesk';
}
.team__slider__card__back p {
  margin: 0;
  margin-top: 1rem;
  color: #fff;
  font-family: 'Space Grotesk';
}
.team__slider__card__back--link {
  margin-top: 2rem;
}
.team__slider__card__back--link a {
  line-height: 0;
  margin-right: 0.5rem;
}
.team__slider__card__back--link a:last-child {
  margin-right: 0;
}
.team__slider__card__back--link a img {
  width: 1.5rem;
}
.partners__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 1136px) {
  .partners__wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.partners__box {
  margin-top: 2rem;
  padding: 3.31rem 3.05rem;
  position: relative;
  border-radius: 1.25rem;
  background: linear-gradient(104deg, rgba(217, 217, 217, 0.2) 26.11%, rgba(217, 217, 217, 0) 115.63%);
  backdrop-filter: blur(25px);
}
.partners__box::before {
  content: '';
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
@media (max-width: 1136px) {
  .partners__box::before {
    background: linear-gradient(90deg, rgba(228, 225, 225, 0.7), rgba(217, 217, 217, 0.2));
  }
}
.partners__box img {
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1136px) {
  .partners__box img {
    max-width: 300px;
    margin: 0 auto;
  }
}
.footer {
  background: #e4001b;
  margin-top: -1.875rem;
  padding-top: 1.875rem;
  border-radius: 24px 24px 0rem 0rem;
  z-index: 2;
  position: relative;
}
.footer__anim {
  background: #101119 !important;
  position: relative;
  padding-top: 5vh;
}
.footer__anim img {
  max-width: 68rem;
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  visibility: hidden;
}
.footer__anim img.animate {
  visibility: visible;
  animation: popup-small 1.5s ease forwards;
  animation-delay: 500;
}
.footer__anim img.char {
  margin-bottom: -5%;
}
.footer__anim img.girl {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 46%;
  transform: translateX(-50%);
  max-height: 95%;
}
.footer__anim img.girl.animate {
  visibility: visible;
  animation: popup 1.2s ease forwards;
}
@keyframes popup {
  0% {
    transform: translateX(-50%) translateY(40%);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}
@keyframes popup-small {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}
.footer__banner {
  width: 100%;
  max-width: 768px;
  margin: 0px auto;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(27, 37, 47, 0.95) 0%, rgba(0, 0, 0, 0.95) 100%);
  border-radius: 1.875rem;
  padding: 2.38rem 5.38rem;
  z-index: 3;
  position: relative;
  margin-top: -70px;
}
.footer__banner__head {
  display: block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
}
@media (max-width: 1136px) {
  .footer__banner__head {
    font-size: 1rem;
  }
}
.footer__banner__input {
  margin-top: 1.13rem;
  border: 1px solid #eff1f7;
  border-radius: 100vw;
  padding: 0.44rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1136px) {
  .footer__banner__input {
    max-width: 450px;
    margin: 0 auto;
  }
}
.footer__banner__input input {
  background: none;
  box-sizing: border-box;
  padding-left: 1rem;
  outline: none;
  border: 0px;
  margin: 0;
  color: #d9d9d9;
  font-family: 'Space Grotesk';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
}
@media (max-width: 1136px) {
  .footer__banner__input input {
    font-size: 0.8rem;
  }
}
.footer__banner__input input:focus {
  background: none;
  outline: none;
  border: 0px;
}
.footer__banner__input button {
  border: 0;
  width: fit-content;
  color: false;
  font-family: 'Space Grotesk';
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 0.56em 1.56em;
  border-radius: 100vw;
  margin: 0.475em;
  background: #e2001b;
  color: #fff;
  margin: 0;
}
@media (max-width: 1136px) {
  .footer__banner__input button {
    font-size: 0.9rem;
  }
}
@media (max-width: 1136px) {
  .footer__banner__input button {
    color: false;
    font-family: 'Space Grotesk';
    font-size: 1rem;
    font-weight: 300;
    line-height: 100%;
  }
}
@media (max-width: 1136px) and (max-width: 1136px) {
  .footer__banner__input button {
    font-size: 0.8rem;
  }
}
@media (max-width: 1136px) {
  .footer__banner__input button {
    padding: 1.2em 1.41em;
  }
}
.footer__banner__input button:focus {
  outline: none;
  border: 0;
}
.footer__banner__input button span {
  position: relative;
  z-index: 1;
  font-family: 'Space Grotesk';
  font-weight: 700;
}
.footer__banner__input button:hover {
  background: #fff;
  color: #e2001b;
}
.footer__banner__input button.theme-back {
  background: #10111a;
}
.footer__wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 1136px) {
  .footer__wrapper {
    padding-bottom: 2rem;
  }
}
@media (max-width: 1136px) {
  .footer__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }
}
.footer__logo {
  width: 22vw;
  height: 34vh;
  padding-bottom: 3vh;
}
.footer__logo video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1136px) {
  .footer__logo {
    width: 70vw;
    height: 20vh;
    margin: auto;
  }
  .footer__logo video {
    object-fit: cover;
  }
}
.footer__content__links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: baseline;
  grid-gap: 0.8125em;
  border-bottom: 2px solid #fff;
  padding-bottom: 1.8125em;
  margin-bottom: 1.8125em;
}
@media (max-width: 1136px) {
  .footer__content__links {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.footer__content__links--item {
  color: #fff;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
  text-decoration: none;
}
@media (max-width: 1136px) {
  .footer__content__links--item {
    font-size: 1rem;
  }
}
.footer__content__links--icons span {
  color: #fff;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.15rem;
  width: 100%;
  margin-bottom: 0.5em;
}
@media (max-width: 1136px) {
  .footer__content__links--icons span {
    font-size: 1rem;
  }
}
.footer__content__links--icons a {
  line-height: 0;
}
.footer__content__links--icons a img {
  width: 1.875em;
  display: inline-block;
  margin-right: 0.8125em;
}
.footer__content__links--icons a:last-child img {
  margin-right: 0;
}
.footer__content__info span {
  color: #eff1f7;
  font-family: 'Space Grotesk';
  font-size: 1rem;
  font-weight: 300;
  line-height: 100%;
}
@media (max-width: 1136px) {
  .footer__content__info span {
    font-size: 0.8rem;
  }
}
.form-show-success {
  display: none;
  align-items: center;
  justify-content: center;
}

#client-hub-username,
#client-hub-password {
  background-color: transparent !important;
}
