.client-hub-documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  min-height: 50vh;

  @media (max-width: 767px) {
    gap: 40px;
  }

  & .title {
    & h1 {
      text-transform: uppercase;
      font-family: 'Resolve Medium';
      letter-spacing: 3px;
      font-size: 42px;
      margin: 0;

      @media (max-width: 1366px) {
        font-size: 32px;
      }
      @media (max-width: 840px) {
        font-size: 28px;
        letter-spacing: 1px;
      }
      @media (max-width: 767px) {
        font-size: 28px;
        text-align: center;
      }
    }
  }

  & .filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media (max-width: 767px) {
      width: 100%;
      overflow-x: scroll;
      justify-content: flex-start;
      gap: 36px;
    }

    & .filter p {
      font-family: 'Space Grotesk';
      font-size: 16px;
      font-weight: 400;
      line-height: 20.42px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #a8a8a8;
      cursor: pointer;

      @media (max-width: 767px) {
        white-space: nowrap;
      }
    }

    & .filter.active p {
      color: #ec3120;
      font-family: 'Space Grotesk SemiBold';
    }
  }

  & .documents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    & .download-box {
      width: 100%;
      background-color: #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.2s ease;
      text-decoration: none;

      &:hover {
        box-shadow: 0px 4px 4px 0px #0000001a;

        & .title p {
          color: #ec3120;
        }
      }

      & .title {
        & p {
          color: #1b1b1b;
          margin: 0;
          font-family: 'Space Grotesk Bold';
          text-transform: uppercase;
          font-size: 22px;

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }

      & .icon {
        width: 24px;
        height: 24px;
        margin-top: -8px;

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
