@import './vars.scss';
@import url('https://fonts.cdnfonts.com/css/louis-george-Café');
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'Resolve Light';
  src: url('./fonts/Resolve-Light.woff2') format('woff2'), url('./fonts/Resolve-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Resolve Bold';
  src: url('./fonts/Resolve-Bold.woff2') format('woff2'), url('./fonts/Resolve-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Resolve Medium';
  src: url('./fonts/Resolve-Medium.woff2') format('woff2'), url('./fonts/Resolve-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Resolve';
  src: url('./fonts/Resolve-Regular.woff2') format('woff2'), url('./fonts/Resolve-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
    url('./fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk SemiBold';
  src: url('./fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
    url('./fonts/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk-Medium.woff2') format('woff2'), url('./fonts/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk Light';
  src: url('./fonts/SpaceGrotesk-Light.woff2') format('woff2'), url('./fonts/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk Bold';
  src: url('./fonts/SpaceGrotesk-Bold.woff2') format('woff2'), url('./fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
