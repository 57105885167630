.client-hub-assets {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  @media (max-width: 1366px) {
    gap: 40px;
  }

  & .title {
    & h1 {
      text-transform: uppercase;
      font-family: 'Resolve Medium';
      letter-spacing: 3px;
      font-size: 42px;
      margin: 0;

      @media (max-width: 1366px) {
        font-size: 32px;
      }
      @media (max-width: 840px) {
        font-size: 28px;
        letter-spacing: 1px;
      }
      @media (max-width: 767px) {
        font-size: 28px;
        text-align: center;
      }
    }
  }

  & .assets-1 {
    display: flex;
    width: 100%;
    gap: 40px;

    @media (max-width: 1366px) {
      flex-direction: column;
      gap: 20px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 50px;
    }

    & .brand-guidelines {
      background-color: #1b1b1b;
      padding: 40px 70px;
      border-radius: 20px;
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1366px) {
        width: 100%;
        padding: 40px;
      }
      @media (max-width: 767px) {
        width: calc(100% + 60px);
        margin-left: -30px;
        padding: 60px 30px;
        gap: 10px;
        border-radius: 30px;
        margin-bottom: 30px;
      }

      & .title {
        & h2 {
          color: #fff;
          font-family: 'Space Grotesk';
          font-size: 32px;
          font-weight: 500;
          line-height: 43.61px;
          text-align: left;
          text-transform: uppercase;
          margin: 0;

          @media (max-width: 1366px) {
            font-size: 28px;
          }
          @media (max-width: 767px) {
            font-size: 26px;
          }
        }
      }

      & .description {
        & p {
          color: #fff;
          font-family: 'Space Grotesk';
          font-size: 16px;
          font-weight: 300;
          line-height: 18px;
          text-align: left;
          margin: 0;
        }
      }

      & .buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }

        & .red-button {
          width: 40%;

          @media (max-width: 767px) {
            width: 70%;
          }
          & button {
            width: 100%;
          }
        }

        & .download {
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 40%;
          cursor: pointer;
          text-decoration: none;

          @media (max-width: 767px) {
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;
            width: unset;
          }
          & span {
            text-decoration: none;
            color: #d9d9d9;
            font-family: 'Space Grotesk';
            font-size: 22px;
            font-weight: 300;
            line-height: 27.91px;
            text-align: left;
            text-transform: uppercase;
          }

          & img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
        }
      }
    }

    & .brand-reference {
      width: 30%;
      position: relative;
      cursor: pointer;

      @media (max-width: 1366px) {
        width: 100%;
        height: 400px;
      }
      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;
        height: unset;
      }

      & img {
        position: relative;
        z-index: 1;
        width: 100%;
        object-fit: contain;
        border-radius: 18px;

        @media (max-width: 1366px) {
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 767px) {
          width: 90%;
        }
      }

      & .on-hover {
        display: none;
      }

      &:hover {
        & .on-hover {
          transition: all 0.2s ease;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 18px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) -151.44%, rgba(0, 0, 0, 0.8) 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          & p {
            color: #fff;
            font-family: 'Space Grotesk';
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 500;
            line-height: 30.62px;
            text-align: left;
          }

          & img {
            width: 30px;
            height: 30px;
            object-fit: contain;
            border-radius: 0;
          }
        }
      }
    }
  }

  & .assets-2 {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 1366px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 60px;
      margin-bottom: 50px;
    }

    & .asset-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      & .image {
        width: 270px;
        height: 270px;

        & img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
      }

      & .name h3 {
        margin: 0;
        font-family: 'Space Grotesk';
        font-size: 24px;
        font-weight: 700;
        line-height: 35.73px;
        text-align: center;
      }

      & .description {
        display: flex;
        justify-content: center;
        & p {
          height: 40px;
          width: 100%;
          margin: 0;
          font-family: 'Space Grotesk';
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.03em;
          text-align: center;
          text-transform: capitalize;
        }
      }

      & .white-button {
        & a {
          text-decoration: none;
        }
        @media (max-width: 767px) {
          margin-top: 0px;
        }
      }
    }
  }

  & .assets-3 {
    display: flex;
    flex-direction: column;
    gap: 50px;

    & .assets {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;

      @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
        &:nth-of-type(2) {
          flex-direction: column-reverse;
        }
        margin-bottom: 50px;
      }

      & .image,
      & .video {
        width: 50%;

        @media (max-width: 1366px) {
          height: 300px;
        }
        @media (max-width: 767px) {
          width: 100%;
        }

        & img,
        & video {
          width: 100%;
          border-radius: 20px;

          @media (max-width: 1366px) {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      & .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;

        @media (max-width: 767px) {
          width: 100%;
        }

        & .title {
          & h2 {
            color: #1b1b1b;
            font-family: 'Space Grotesk';
            font-size: 28px;
            font-weight: 500;
            line-height: 43.61px;
            text-align: left;
            margin: 0;

            @media (max-width: 767px) {
              font-size: 24px;
            }
          }
        }

        & .description {
          & p {
            color: #1b1b1b;
            font-family: 'Space Grotesk';
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
            margin: 0;
          }
        }

        & .white-button {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }

  & .assets-4 {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    & .banners-social-media {
      position: relative;
      display: flex;
      width: 100%;
      background-color: #1b252f;
      padding: 50px 40px;
      border-radius: 20px;

      @media (max-width: 767px) {
        padding: 60px 20px;
        width: calc(100% + 60px);
        margin-left: -30px;
        border-radius: 30px;
        margin-bottom: 50px;
      }
      & .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;

        @media (max-width: 1366px) {
          width: 100%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }

        & .title {
          & h2 {
            color: #fff;
            font-family: 'Space Grotesk';
            font-size: 28px;
            font-weight: 500;
            text-align: left;
            margin: 0;
            text-transform: uppercase;
          }
        }

        & .description {
          & p {
            color: #fff;
            font-family: 'Space Grotesk';
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
            margin: 0;
          }
        }

        & .image.mobile {
          position: relative;
          width: 100%;
          height: 100%;
          top: -12%;
          right: 12%;
          display: block;

          & img {
            width: 120%;
            height: 120%;
          }

          @media (min-width: 1366px) {
            display: none;
          }
        }

        & .white-button {
          display: flex;
          justify-content: flex-start;
          width: 50%;

          & a {
            width: 100%;
          }
          @media (max-width: 767px) {
            justify-content: center;
            width: 100%;
            margin-top: -8%;

            & a {
              width: 70%;
            }
          }
        }
      }

      & .image {
        position: absolute;
        top: -38%;
        right: 15%;
        width: 500px;
        height: 500px;

        @media (max-width: 1366px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: none;
        }

        & img {
          width: 140%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & .white-button {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    & a,
    & button {
      text-align: center;
      text-decoration: none;
      width: 70%;
      height: 100%;
      padding: 12px 42px;
      border-radius: 32px;
      border: 1.5px solid #ec3120;
      background-color: transparent;
      font-family: 'Space Grotesk';
      font-size: 18px;
      font-weight: 500;
      color: #ec3120;
      transition: all 0.2s ease;

      &:hover {
        background-color: #ec3120;
        color: #fff;
      }
    }
  }

  & .red-button {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    & a,
    & button {
      text-align: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      padding: 12px 32px;
      border-radius: 32px;
      border: 1.5px solid #ec3120;
      background-color: transparent;
      font-family: 'Space Grotesk';
      font-size: 18px;
      font-weight: 500;
      color: #ec3120;
      transition: all 0.2s ease;

      &:hover {
        background-color: #ec3120;
        color: #fff;
      }
    }
  }
}
