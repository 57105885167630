.indexedStep {
  color: #44454f;
  width: 20px;
  height: 20px;
  font-size: 12px;

  background: #44454f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background: #57bc18;
  color: #57bc18;
}
.indexedStep.current {
  background: white !important;
  color: white !important;
}

.indexedStep.yellow {
  background: #eae111 !important;
  color: #eae111 !important;
}

.RSPBprogressBar {
  background-color: #44454f !important;
}
// background: #57bc18;
// background: #eae111;
// background: #44454f;
