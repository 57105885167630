.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 80px;
  height: 80px;
}

.loader-circle {
  width: 80px;
  height: 80px;
  fill: none;
  stroke: #d31010;
  stroke-width: 8;
  stroke-dasharray: 251;
  stroke-dashoffset: 251;
}

.loader.animate .loader-circle {
  animation: circleFillAnimation 1s ease forwards;
}

.loader-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes circleFillAnimation {
  0% {
    stroke-dashoffset: 251;
  }
  100% {
    stroke-dashoffset: 31;
  }
}

.wrapper {
  height: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #d31010;
  fill: none;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #d31010;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #d31010;
  animation: fill 0.3s ease-in-out 0.3s forwards, scale 0.2s ease-in-out 0.8s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 0px #d31010;
  }
}

.thank-you .modal-dialog .modal-content{
  width: 60vw;

  @media (max-width: 1136px) {
    width: 90vw;
  }
}


