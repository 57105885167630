.client-hub {
  background-color: #fff;

  & header {
    background-color: #000;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 140px;
    width: 100%;
    position: relative;
    z-index: 10;

    @media (max-width: 767px) {
      padding: 20px 30px;
    }

    & .wrap {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & .logo {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 140px;
      & img {
        width: 100%;
      }
    }

    & .menu {
      display: flex;
      gap: 40px;

      @media (max-width: 767px) {
        display: none;
      }

      & a {
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid #e2001b;
        }
      }
    }

    & button {
      width: 30px;
      height: 30px;
      background: none;
      background-image: url('../../images/client-hub/exit.svg');
      border: none;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  & .client-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    min-height: 100vh;

    & .client-area-content {
      max-width: 1440px;
      width: 100%;
      padding: 80px 120px;

      @media (max-width: 1366px) {
        max-width: 1000px;
      }
      @media (max-width: 840px) {
        max-width: 700px;
        padding: 60px;
      }
      @media (max-width: 767px) {
        max-width: 500px;
        padding: 30px;
      }
    }
  }

  & .client-hub-footer {
    width: 100vw;
    background-color: #e2001b;
    display: flex;
    justify-content: space-between;
    padding: 50px 200px;

    @media (max-width: 1366px) {
      padding: 40px 120px;
    }
    @media (max-width: 840px) {
      padding: 35px 60px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      padding: 35px 60px;
      gap: 50px;
    }

    & .footer-col {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 767px) {
        align-items: center;
        gap: 10px;
      }

      &.second {
        align-items: flex-end;

        @media (max-width: 767px) {
          align-items: center;
          gap: 40px;
        }
      }
    }

    & .footer-row {
      display: flex;

      @media (max-width: 767px) {
        justify-content: center;
      }

      & h1,
      & h3,
      & a {
        color: #fff;
        margin: 0;
      }

      & h1 {
        font-size: 26px;
        font-family: 'Resolve Medium';
        text-transform: uppercase;
        letter-spacing: 1px;

        @media (max-width: 840px) {
          font-size: 22px;
        }
      }

      & h3 {
        font-size: 16px;
        font-family: 'Space Grotesk';
        width: 70%;

        @media (max-width: 767px) {
          text-align: center;
          width: 100%;
        }

        & a {
          text-transform: none;
          text-decoration: underline;
          letter-spacing: normal;
          font-size: 16px;
          font-family: 'Space Grotesk';
        }
      }

      & a {
        font-family: 'Space Grotesk SemiBold';
        font-size: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;

        @media (max-width: 840px) {
          font-size: 16px;
          letter-spacing: 1px;
          text-align: center;
        }
      }

      &.menu {
        display: flex;
        gap: 20px;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }

      &.social {
        display: flex;
        justify-content: flex-end;
        gap: 50px;

        @media (max-width: 840px) {
          margin-top: 10px;
        }
      }
    }
  }

  & .burger-menu {
    display: none;
    width: 25px;
    height: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @media (max-width: 767px) {
      display: block;
    }
  }

  & .burger-menu span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  & .burger-menu span:nth-child(1) {
    top: 0px;
  }

  & .burger-menu span:nth-child(2) {
    top: 7.5px;
  }

  & .burger-menu span:nth-child(3) {
    top: 15px;
  }

  & .burger-menu.open span:nth-child(1) {
    top: 7.5px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  & .burger-menu.open span:nth-child(2) {
    opacity: 0;
    left: -30px;
  }

  & .burger-menu.open span:nth-child(3) {
    top: 7.5px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  & .burger-content {
    display: none;
    width: 100vw;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    background: #000;
    top: 99%;
    z-index: -1;
    box-shadow: 0px 4px 4px 0px #00000008;
    border-radius: 0 0 32px 32px;

    &.open {
      display: block;
    }

    & .burger-navigation {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style: none;

      & li {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        width: 100%;
        & a {
          color: #ffffff;
          cursor: pointer;
          font-size: 18px;
          text-decoration: none;

          &:hover {
            color: #e2001b;
          }
        }
      }

      & button {
        display: block;
      }
    }
  }
}
