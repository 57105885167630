.client-hub-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  @media (max-width: 1366px) {
    gap: 40px;
  }

  & .title {
    & h1 {
      text-transform: uppercase;
      font-family: 'Resolve Medium';
      letter-spacing: 3px;
      font-size: 42px;
      margin: 0;

      @media (max-width: 1366px) {
        font-size: 32px;
      }
      @media (max-width: 840px) {
        font-size: 28px;
        letter-spacing: 1px;
      }
      @media (max-width: 767px) {
        font-size: 28px;
        text-align: center;
      }
    }
  }

  & .games-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 60px 40px;

    @media (max-width: 1366px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 20px;

      & > :nth-child(n) {
        justify-self: center;
      }
    }

    @media (max-width: 840px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 1366px) {
      & > :nth-child(3n + 1) {
        justify-self: start;
      }

      & > :nth-child(3n + 2) {
        justify-self: center;
      }

      & > :nth-child(3n) {
        justify-self: end;
      }
    }

    & .game-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 340px;

      @media (max-width: 1366px) {
        width: 320px;
      }
      @media (max-width: 840px) {
        width: 260px;
      }
      @media (max-width: 767px) {
        width: 360px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      & .image {
        width: 100%;
        height: 380px;
        box-shadow: none;
        position: relative;
        transition: all 0.5s ease;

        @media (max-width: 1366px) {
          height: 300px;
        }
        @media (max-width: 840px) {
          height: 280px;
        }

        & img {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
          box-shadow: none;
          border-radius: 32px 32px 0 0;
        }

        & .hover-effect {
          display: none;
        }
      }

      & .info {
        background: #1b1b1b;
        width: 100%;
        border-radius: 0 0 32px 32px;
        padding: 18px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        & .wrap {
          display: flex;
          flex-direction: column;

          & h3,
          & h5 {
            margin: 0;
            color: #fff;
            font-family: 'Space Grotesk Bold';
          }

          & h3 {
            font-size: 20px;
            cursor: pointer;
          }

          & h5 {
            font-size: 16px;
          }
        }

        & .button {
          width: 100%;
          @media (max-width: 840px) {
            display: flex;
            justify-content: center;
          }
          & button {
            transition: all 0.5s ease;
            width: 100%;
            padding: 12px 24px;
            border-radius: 32px;
            border: 1.5px solid #ec3120;
            font-size: 18px;
            font-family: 'Space Grotesk SemiBold';
            background-color: transparent;
            color: #fff;

            @media (max-width: 840px) {
              padding: 10px 20px;
              width: 90%;
              font-size: 16px;
            }
          }
        }
      }

      &:hover {
        transition: all 0.5s ease;
        & .image {
          transition: all 0.5s ease;
          height: 372px;

          @media (max-width: 767px) {
            height: 260px;
          }

          & .hover-effect {
            transition: all 0.5s ease;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.3);
            border-radius: 32px 32px 0 0;

            & p {
              text-transform: uppercase;
              color: #fff;
              font-family: 'Space Grotesk SemiBold';
              font-size: 20px;
            }

            & img {
              width: 30px;
              height: 30px;
              object-fit: contain;
              border-radius: 0;
            }
          }
        }

        & .info {
          & .button {
            width: 100%;
            & button {
              transition: all 0.5s ease;
              background-color: #ec3120;
              color: #fff;
            }
          }
        }
      }
    }
  }

  & .games-banner {
    width: 100%;
    background: #1b252f;
    display: flex;
    position: relative;
    border-radius: 24px;

    @media (max-width: 840px) {
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      margin-top: 0;
    }

    & .left {
      width: 60%;
      padding: 10px 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 1366px) {
        width: 80%;
        padding: 40px;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding: 30px;
      }

      & h3,
      & p {
        color: #fff;
      }

      & h3 {
        font-family: 'Space Grotesk SemiBold';
        text-transform: uppercase;
        font-size: 34px;
        margin: 0;

        @media (max-width: 840px) {
          font-size: 26px;
        }
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      & p {
        font-family: 'Space Grotesk Light';
        width: 70%;

        @media (max-width: 840px) {
          width: 90%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    & .girl {
      position: absolute;
      left: 35%;
      bottom: 0;
      height: 120%;

      @media (max-width: 1366px) {
        left: 50%;
      }
      @media (max-width: 840px) {
        height: 130%;
        width: 120%;
        left: 16%;
      }
      @media (max-width: 767px) {
        display: none;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 840px) {
          object-fit: contain;
        }
      }
    }

    & .right {
      width: 40%;

      @media (max-width: 1366px) {
        display: none;
      }

      & img {
        width: 100%;
        border-radius: 24px;
      }
    }
  }
}
